<template>
  <article id="wrap">
      <AppHeader theme="white">利用規約</AppHeader>

      <section class="contents">
        <div class="contentInner pb_80">
          <div class="white_bg_area2">
            <div class="item">
              <div>
                この利用規約（以下、「本規約」といいます）は、株式会社ハイスピードボーイ（以下、「当社」といいます）が本サイト上で提供するサービスを、ユーザーが利用する際の一切の行為に適応されます。ユーザーは本規約に承認の上本サービスを利用するものとし、ユーザーが本サービスのご利用を開始した時点で、本規約を承認したものとみなします。
              </div>
            </div>

            <div class="item">
              <div class="item_title">第１条（定義）</div>
              <div>
                本規約において使用する言語の意味は、次の各号に定める通りとします。
              </div>
              <ol class="par">
                <li>
                  本サイト<br />
                  当社が運営する「すてきなせかい」と称する<br>
                  ウェブサイト（https://sutekinasekai.net/）を指します。
                </li>
                <li>
                  本サービス<br />
                  本サイト上で提供されるすべてのサービスを指します。
                </li>
                <li>
                  ユーザー<br />

                  本サイトを利用するために有料会員登録している者を指します。
                </li>
                <li>
                  登録メールアドレス<br />

                  本サービスの提供を受ける目的で、ユーザーが当社に提供したメールアドレスの情報を指します。
                </li>
                <li>
                  パスワード<br />
                  本サービスを利用するにあたり、登録メールアドレスと照合して本人を識別するための文字列を指します。
                </li>
                <li>
                  トークルーム<br />
                  ユーザーが他のユーザーと交流を行うことを目的とした、他のユーザーに向けて月額課金制の交流ページを指します。
                </li>
                <li>
                  コメント等の情報
                  <br />
                  コメント、画像、動画リンクのURL等、本サービスを利用して投稿できる情報を指します。
                </li>
                <li>
                  投稿
                  <br />
                  ユーザーが本サイトにコメント等の情報をアップロードする行為を指します。
                </li>
                <li>
                  デジタル・コンテンツ
                  <br />
                  「デジタル・コンテンツ」とは、電子書籍、オンライン講座、動画、音声など、電子的手段によって提供されるコンテンツを指します。
                </li>                
                <li>
                  グッズ・コンテンツ
                  <br /> 
                「グッズ・コンテンツ」とは、衣類、小物、写真、カード、宝飾、その他ユーザーの私物等の販売用コンテンツを指します。
                </li>                
              </ol>
            </div>

            <div class="item">
              <div class="item_title">第２条（入会）</div>
              <ol>
                <li>
                  本サービスの利用を希望する者は、本規約の内容に同意した上で、当社が定める方法により、入会の申し込みを行うものとします。
                </li>
                <li>
                  本サービスの利用を希望する者が未成年者である場合は、本サービスを利用することについて、法定代理人の承諾を得るものとします。
                </li>
                <li>
                  入会の申し込みをした者（以下、「入会申込者」といいます）は、当社が入会の申し込みを承認した時点で登録会員（以下、「登録会員」といいます）になります。
                </li>
                <li>
                  登録会員は、ユーザーになるため当社の指定する登録手続きにより有料会員登録を行う必要があります。登録会員の有料会員申し込みに対し、当社の審査を経てこれに承諾した場合に、ユーザーと当社の間に有料会員契約（以下、「有料会員契約」といいます）が成立します。
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">第３条（入会申込の不承諾）</div>
              <div>
                当社は次の各号のいずれかに該当する場合は、当社の判断によって入会申込者の入会申し込みを承諾しないことがあります。
              </div>
              <ol class="par">
                <li>
                  入会申込者が、前条第１-２項の方法によらず入会の申し込みを行なった場合
                </li>
                <li>
                  入会申込者が、過去に本規約に違反したことを理由として強制退会処分を受けた者である場合。
                </li>
                <li>その他、当社が不適切と判断した場合。</li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">第４条（退会）</div>
              <ol>
                <li>
                  ユーザーが退会を希望する場合は、ユーザーは当社指定の方法により、当社に退会の申し出を行うものとします。
                </li>
                <li>
                  当社は、ユーザーが次の各号に掲げるいずれかの行為を行なった場合、有料会員契約成立後であってもこれを即時解除し、ユーザーを強制的に退会させ、本サービスの利用をお断りすることがあります。
                  <ol class="par">
                    <li>
                      第３条第１-２項の方法によらず入会の申し込みを行ったことが明らかになった場合。
                    </li>
                    <li>本規約に違反した場合</li>
                    <li>その他、当社が不適切と判断した場合。</li>
                    <li>
                      当社が指定する決済方法の決済サービス会社よりユーザーの決済が停止または無効扱いとされた場合。
                    </li>
                    <li>
                      その他、前各号に準ずる不都合な事由があると当社が判断した場合。
                    </li>
                  </ol>
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">
                第５条（登録メールアドレスおよびパスワードの管理）
              </div>
              <ol>
                <li>
                  ユーザーは、登録メールアドレスおよびパスワードを自己の責任において適切に管理し、第三者に利用させ、または貸与、譲渡、名義変更、売買等してはなりません。
                </li>
                <li>
                  ユーザーは、登録メールアドレスおよびパスワードの管理不十分、使用上の過誤、第三者による利用その他理由の如何を問わず登録メールアドレスおよびパスワードが利用されたときは、ユーザーが利用したとみなされます。
                </li>
                <li>
                  ユーザーの管理不十分その他当社の帰すべき事由によらず生じた登録メールアドレスおよびパスワードに関する損害については、ユーザーがその責任を負うものとし、当社は一切の責任を負いません。
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">
                第６条（利用料）
              </div>
              <div>
                本サービスのご利用料は、別途当社ウェブサイト等に掲載するものに従うものとします。
              </div>
            </div>



            <div class="item">
              <div class="item_title">第7条（ユーザーの責任）</div>
              <ol>
                <li>
                  ユーザーは、ユーザー自身の自己責任において本サービスを利用するものとし、本サービスを利用してなされた一切の行為およびその結果について一切の責任を負います。
                </li>
                <li>
                  本サービスを利用してユーザーが投稿したコメント等の情報に関する責任はユーザー自身にあります。当社はユーザーが本サービスを利用して投稿したコメント等の情報の内容について一切責任を負いません。
                </li>
                <li>
                  ユーザーが他人の名誉を毀損した場合、プライバシー権を侵害した場合、許可なく第三者の個人情報等を開示した場合、著作権法に違反する行為を行なった場合その他他人の権利を侵害した場合には、当該ユーザーは自身の責任と費用においてこれを解決しなければなりません。ただし、当社が本サービスの円滑な運営のために必要であると判断した場合には当該トラブルの解決に向けて適切な措置を講じるものとします。
                </li>
                <li>
                  ユーザーは、ユーザーが本サービスを利用して投稿したコメント等の情報について当社に保存義務がないことを認識し、必要なコメント等の情報については適宜バックアップ等を行うものとします。
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">第8条（禁止事項）</div>
              <ol>
                <li>
                  ユーザーは本サービスに使用されているソフトウェア、ドキュメント、データ、画像、キャラクター、ロゴ等につき、次に定めることを行なってはなりません。
                  <ol class="par">
                    <li>
                      本サービスを手段として直接に金銭その他商業的利益を求めること。
                    </li>
                    <li>
                      複製、頒布、公衆送信、改変、翻訳、翻案または二次的著作物の作成をすること。
                    </li>
                    <li>
                      第三者に移転、販売、譲渡その他の処分をすること。
                    </li>
                    <li>
                      際使用許諾、貸与その他の方法の如何を問わず、第三者に使用させること。
                    </li>
                    <li>
                      リーバースエンジニアリングその他の解析行為をすること。
                    </li>
                    <li>
                      法令等に違反する、または違反する可能性がある行為をすること。
                    </li>
                    <li>
                      当社もしくは第三者の権利を侵害、制限、妨害し、またはそのおそれがある行為をすること。
                    </li>
                    <li>
                      その他、当社が不適切と判断する行為をすること。
                    </li>
                  </ol>
                </li>
                <li>
                  ユーザーは本サービスの利用に関連して、次に定めることを行ってはなりません。
                  <ol class="par">
                    <li>本規約に違反する行為をすること。</li>
                    <li>
                      法令等に違反する行為、または違反する可能性がある行為をすること。
                    </li>
                    <li>公序良俗に反する行為をすること。</li>
                    <li>
                      第三者の特許権、商標権、著作権、実用新案権、意匠権、ノウハウその他知的財産権等の権利を侵害すること。
                    </li>
                    <li>第三者のプライバシーを損害すること。</li>
                    <li>第三者の営業秘密、機密情報等を漏洩すること。</li>
                    <li>
                      第三者を脅迫、中傷、虐待等する行為、または第三者の本サービス利用を妨害する行為。
                    </li>
                    <li>
                      猥褻、アダルト、その他第三者が不愉快であると感じる可能性があるコメント等の情報を投稿すること。
                    </li>
                    <li>
                      人の殺害、傷害現場、死体、その他残虐な行為を撮影したコメント等の情報を投稿すること
                    </li>
                    <li>
                      真実ではない情報、コメント等の情報を投稿すること。
                    </li>
                    <li>
                      第三者の個人情報を交換。配信、投稿、収集等すること。
                    </li>
                    <li>
                      異性交際を求め、または異性交際の求めに応じる行為をすること。
                    </li>
                    <li>異性交際に関する情報を投稿すること。</li>
                    <li>第三者を自殺に誘引または勧誘する行為。</li>
                    <li>
                      第三者のウェブサイト、コンテンツ等へのリンク等を設置すること。
                    </li>
                    <li>本サービスを商用目的に利用すること。</li>
                    <li>他人になりすます行為をすること。</li>
                    <li>大量の電子メール、広告等を投稿すること。</li>
                    <li>
                      コンピュータウィルス、ワーム、トロイの木馬、その他有害または不正なプログラムを投稿すること。
                    </li>
                    <li>本サービスの運営を妨害する行為をすること。</li>
                    <li>
                      同一人物によるアカウントの重複取得行為をすること。
                    </li>
                    <li>
                      その他、当社が不適切と判断する行為をすること。
                    </li>
                  </ol>
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">
                第9条（サービスの中断、変更、終了等）
              </div>
              <ol>
                <li>
                  当社は、次の各号のいずれかに該当する事由が生じた場合には、ユーザーに事前通告することなく、本サービスの一部または全部の提供を中断する場合があります。
                  <ol class="par">
                    <li>
                      本サービスを提供するためのシステムの保守点検を定期的にまたは緊急に行う場合。
                    </li>
                    <li>
                      天災地変、火災、停電、騒乱等により本サービスの提供ができない場合。
                    </li>
                    <li>
                      電気通信回路等の通信手段またはコンピューターシステムの障害、不正アクセスまたはコンピュータウイルスなどにより本サービスの提供ができない場合。
                    </li>
                    <li>
                      法律、法令に基づく措置等により本サービスの提供ができない場合。
                    </li>
                    <li>
                      全各号の他、当社がやむを得ないと判断する場合。
                    </li>
                  </ol>
                </li>
                <li>
                  当社は、当社の裁量において、ユーザーに事前に通告することなく、本サービスの内容の変更または提供の終了をすることができるものとします。
                </li>
                <li>
                  当社は前１-２項の中断、内容変更または提供終了に関連してユーザーに生じた損害につき、一切責任を負いません。
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">
                第10条（トークルームの開設）
              </div>
              <ol>
                <li>
                  ユーザーが本サイト上でトークルームの利用を申し込み、トークルームを主催するユーザーが参加を希望するユーザーに対しこれを承諾する旨の通知をした時点で、ユーザー間にトークルーム利用契約が成立します。
                </li>
                <li>
                  参加ユーザーは、トークルーム利用契約を締結することにより、トークルーム毎に設定された会費の支払い義務が発生することに同意するものとします。
                </li>
                <li>
                  各トークルームの料金体系及び支払方法は、本サイトに掲示するものとします。
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">
                第11条（参加者による支払い）
              </div>
              <ol>
                <li>
                  ユーザーが本サイト上でトークルームの利用を申し込み、トークルームを主催するユーザーが参加を希望するユーザーに対しこれを承諾する旨の通知をした時点で、ユーザー間にトークルーム利用契約が成立します。
                </li>
                <li>
                  参加ユーザーは、トークルーム利用契約を締結することにより、トークルーム毎に設定された会費の支払い義務が発生することに同意するものとします。
                </li>
                <li>
                  参加ユーザーは当社が本サイト上で指定する決済サービスを使用してトークルーム会費を支払うものとします。
                </li>
                <li>
                  参加ユーザーがクレジットカード決済を使用する場合、各クレジットカード会社との契約を遵守するものとし、クレジットカード会社との紛争は、参加ユーザーと当該クレジットカード会社との間で解決するものとします。
                </li>
              </ol>
            </div>


            <div class="item">
              <div class="item_title">
                第12条（コメント等の情報の権利）
              </div>
              <ol>
                <li>
                  ユーザーは、本サービス内で任意に開示、投稿、送信、掲載等を行う情報に含まれる知的財産権について、当該権利を自ら保有していること、自らが正当にその利用を許諾されていることを保証するものとします。万一、第三者よりユーザーが任意に発信した情報について異議が申し立てられた場合、当該ユーザーは自身の責任と費用においてこれを解決しなければならず、当社は一切の責任を負いません。
                </li>
                <li>
                  当社は、自らの裁量に基づき、ユーザーが本サービス内で開示する情報につき、本規約に反すると判断した場合は制裁制限措置を行うことができるものとします。
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">
                第13条（コメント等の情報の使用許諾等）
              </div>
              <ol>
                <li>
                  本サービスを利用して投稿されたコメント等の情報の権利は、創作したユーザーに帰属します。
                </li>
                <li>
                  当社は、ユーザーが投稿するコメント等の情報を、本サービスの円滑な提供、当社システムの構築、改良。メンテナンスに必要な範囲内で、使用することができるものとします。
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">
                第14条（インターネット販売）
              </div>
              <ol>
                <li>
                  ユーザーが他のユーザーの商品の購入を希望する場合、当該ユーザーは当社の指定する方法に従って商品の購入を申し込むものとします。
                </li>
                <li>
                  当社がユーザーに対して、前項の申込みを承諾する旨のメールをユーザー宛に発信した時点でユーザーと当社との間に当該商品に関する売買契約が成立するものとします。
                </li>
                <li>
                  前項の商品の所有権は、商品の引渡しが行われた時点で当社または他のユーザーからユーザーに移転するものとします。
                </li>
                <li>
                  本サービスによる商品の配送は、日本国内に限るものとします。
                </li>
                <li>
                  本サイト上の売買契約は、クレジットカードでのみ決済します。なおクレジットカード情報を当社で保管することはありません。
                </li>
                <li>
                  ご注文商品は、当社が指定する方法にてごユーザーが配送先に指定した場所へ発送します。売買契約成立後、発送段階での商品の汚損、瑕疵、または商品違いなど当社に責任を帰すべき事由以外では、返品・交換に応じることはできません。また、当社に責任を帰すべき事由を除き、直接的および間接的にごユーザーまたは第三者に発生した損害について、いかなる責任も負いません。
                </li>
                <li>
                  以下に定める各項目にあたる事由が生じた場合、当社は当該契約を解除することができるものとします。また、各項目の記述に関わらず、本サービス利用に関してユーザーの不正行為または不適切な行為があった場合、当社は売買契約を取り消しもしくは解除、その他適切な措置をとることができるものとします。
                  <ol class="par">
                    <li>
                      ユーザーが本規約に違反した場合。
                    </li>
                    <li>
                      ユーザー指定のクレジットカード会社からカード与信不履行の旨の連絡があった場合。
                    </li>
                    <li>
                      ユーザーの支払い能力が危うくなったと認めうる事情が判明した場合。
                    </li>
                    <li>
                      商品が品切れとなり、容易にお届けできない場合。
                    </li>
                    <li>
                      届先不明・長期不在などで商品のお届けができない場合。
                    </li>
                  </ol>                       
                </li>
                <li>
                  商品の画像及びデザイン等全てのコンテンツに関する著作権または商標権、その他全ての知的財産権は当社またはその他の著作権者等正当な権利者に帰属するものであり、ユーザーはこれらの権利を侵害する行為を行わないものとします。また、当社の書面による事前の承諾なく、著作権法で定める会員の私的利用の範囲を超える使用をしてはなりません。
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">
                第15条（デジタル・コンテンツの購入）
              </div>
              <ol>
                <li>
                  一旦購入されたデジタル・コンテンツの返金・交換はできません。ただし第2項に該当する場合はこの限りではありません。
                </li>
                <li>
                  当社側でのシステム障害でデジタル・コンテンツの購入や配信が完了できなかった場合、当社は再配信や返金の対応を取るものとします。
                </li>
                <li>
                  当社は、以下のいずれかまたは両方の方法でデジタル・コンテンツ購入者に、デジタル・コンテンツに関連する配信または通知を送付するものとします。
                  <ol class="par">
                    <li>
                      デジタル・コンテンツ購入者が登録したメールアドレスへの電子メール送信。
                    </li>
                    <li>
                      本サイト上での表示またはダウンロード。
                    </li>
                  </ol>                  
                </li>
                <li>
                  デジタル・コンテンツ購入者は以下の行為を行わないものとします。
                  <ol class="par">
                    <li>
                      法令上認められている場合を除き、当社の事前の同意なくデジタル・コンテンツを改変、複写、複製、表示、配信、配布、出版、販売、譲渡する行為、またはこれらの目的で保管する行為。
                    </li>
                    <li>
                      当社または第三者の知的財産権（著作権、意匠権、実用新案権、商標権、特許権を含むがこれに限定されない）を侵害する行為。
                    </li>
                    <li>
                      当社に不利益を与えると当社が判断する行為。
                    </li>
                    <li>
                      公序良俗や法令に反する行為。
                    </li>
                    <li>
                      その他当社が不適切と判断する行為。
                    </li>
                  </ol>                  
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">
                第16条（グッズ・コンテンツの売買）
              </div>
              <ol>
                <li>
                  ユーザーはユーザー同士で、グッズ・コンテンツの売買を行うことができます。本サービスはユーザー間で商品を販売するためのプラットフォームを提供するサービスであり、売買契約は、本条及びユーザーが別途提示するところに従って、ユーザー間に成立します。
                </li>
                <li>
                  当社は、ユーザーが投稿するコメント等の情報を、本サービスの円滑な提供、当社システムの構築、改良。メンテナンスに必要な範囲内で、使用することができるものとします。
                </li>
                <li>
                  ユーザーは、売買契約成立後のキャンセルはできません。ただし、天災等のやむを得ない事情があるものとして当社がキャンセルを承諾した場合はその限りではありません。
                </li>
                <li>
                  販売したユーザーは、商品に不適合がある場合、商品説明と実際の商品が異なる場合、梱包の不備により配送時に商品が破損したなどの場合は販売したユーザーが責任を負うことに同意し、返金、商品の引取、又は交換等の対応を、販売したユーザーの費用と責任において、直接購入したユーザーに対して行うものとします。
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">第17条（免責事項）</div>
              <ol>
                <li>
                  当社は、本サービスの内容について、および本サービスの利用または起用不能に関連して生じる損害について、一切の責任を負いません。ユーザーは、ユーザーの責任に基づき本サービスをご利用下さい。
                </li>
                <li>
                  当社は、本サービスの正確性、完全性、安全性、本サービスがユーザーの特定の目的に適合しまたは有効であること、本サービスが不具合なく動作することについて何等保証するものではありません。
                </li>
                <li>
                  当社は、本サービスの利用においてユーザー間、ユーザーと第三者、第三者間で生じたトラブルに関して、当社が本サービスの円滑な運営のために必要であると判断した場合には当該トラブルの解決に向けて適切な措置を講じるものとします。
                </li>
                <li>
                  本規約に基づくユーザーと当社の契約が消費者契約法（平成12年法律第1号）第２条第３項の消費者契約に該当する場合には、本規約のうち、当社の責任を完全に免責する規定は適用されないものとします。この場合においてユーザーに生じた損害が当社の債務不履行または不法行為に基づくときには、当社に故意または重大な過失がある場合を除き、当社は、ユーザーが現実に被った直接かつ通常の損害についてのみ賠償責任を負うものとし、当該責任に基づく賠償額は当該ユーザーの１ヶ月分の利用料に相当する金額を超えないものとします。
                </li>
                <li>
                  デジタル・コンテンツの内容について、当社または制作者はいかなる保証も行いません。
                </li>
                <li>
                  当社は、当社の都合により、デジタル・コンテンツの内容変更または提供終了ができるものとします。
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">
                第18条（本規約の変更）
              </div>
              <ol>
                <li>
                  当社は、以下の各号のいずれかに該当する場合、ユーザーの承諾を得ることなく、本規約を変更できるものとします。<br>
                  (1) 変更内容がサービス名や表現の変更又は誤字、脱字の修正等であり、本規約の内容に実質的に影響しない場合<br>
                  (2) 変更内容がユーザーの一般の利益に適合する場合<br>
                  (3) 変更内容が契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他変更に係る事情に照らして合理的なものである場合<br>
                </li>
                <li>
                  当社は、前項第２号及び前項第３号による変更の場合、本規約変更の効力発生の相当期間前までに、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を当社ウェブサイト等への掲載その他当社が適当と判断する方法により通知します。なお、前項第1号による変更の場合、変更後の本規約の内容を当社ウェブサイト等への掲載その他当社が適当と判断する方法により通知した時点で変更後の本規約の効力が発生するものとします。
                </li>
              </ol>
            </div>

            <div class="item">
              <div class="item_title">第19条（個人情報の取扱い）</div>
              <div>
                当社は、個人情報を「プライバシーポリシー」に基づき、適切に取り扱うものとします。
              </div>
            </div>

            <div class="item">
              <div class="item_title">第20条（準拠法および管轄裁判所）</div>
              <ol>
                <li>
                  本サービスその他本規約に関する準拠法は、日本法とします。
                </li>
                <li>
                  本サービスに関し、ユーザーと当社との間で訴訟が生じた場合、東京地方裁判所を第１審の専属的管轄裁判所とします。
                </li>
              </ol>
            </div>
          </div>
          <!-- .white_bg_area2 -->
        </div>
        <!--.contentInner-->
      </section>
      <!--.contents-->

      <AppFooter fixed theme="yellow"></AppFooter>
  </article>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
export default {
  name: "Rule",
  components: { AppHeader, AppFooter },
};
</script>

<style scoped>
ol.par li {
  list-style-type: none;
  counter-increment: cnt;
}

ol.par li::before {
  content: "(" counter(cnt) ")";
  display: inline-block;
  margin-left: -2.5em; /* サイトに合せて調整 */
  width: 2.5em; /* サイトに合せて調整 */
}
</style>
